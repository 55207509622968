import * as React from 'react'
import { HashRouter as BrowserRouter, Route, Link, Switch } from 'react-router-dom'
import { Box } from '@material-ui/core'

//import {Index} from '../../pages/Index';
import {SimpleActions} from '../../pages/SimpleActions';
import {IFrameActions} from '../../pages/IFrameActions';
import {ButtonRandomId} from '../../pages/ButtonRandomId';

import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { ThemeProvider } from '@material-ui/styles';
import { ClickDelayRedirect } from '../../pages/ClickDelayRedirect';

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: red[500],
    }
  },
});

export const AppContainer = (): JSX.Element => {
  return (
    <BrowserRouter>
      <div>
      <ThemeProvider theme={theme}>
        <nav>
          <Box m={2}>
            <Link to="/simpleactions">Simple Actions</Link>
          </Box>
          <Box m={2}>
            <Link to="/iframeactions">IFrame Actions</Link>
          </Box>         
          <Box m={2}>
            <Link to="/clickdelayredirect">Click Delay Redirect</Link>
          </Box>     
          <Box m={2}>
            <Link to="/buttonrandomid">Button Random Id</Link>
          </Box>     
        </nav>
        <Switch>
          <Route path="/simpleactions" component={SimpleActions} />
          <Route path="/iframeactions" component={IFrameActions} />
          <Route path="/clickdelayredirect" component={ClickDelayRedirect} />
          <Route path="/buttonrandomid" component={ButtonRandomId} />
        </Switch>
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
};
